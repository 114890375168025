import i18next from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";
import "./PublicOffer.style.css";

const PublicOfferInfo = () => {
    const { t } = useTranslation();
    const language = i18next.language;

    useEffect(() => {
        document.title = "Initial Public Offering Info - Cevher";
    }, []);

    return (
        <div>
            <Navbar />
            <div className="c-wrapper">
                <div className="c-hero">
                    <div className="c-hero-background">
                        <picture>
                            <img
                                className="c-hero-image"
                                alt=""
                                src={require("../../assets/hero/public-offering.jpg")}
                                style={{ width: "100%" }}
                            />
                        </picture>
                    </div>
                    <div className="c-hero-body">
                        <div className="c-about-caption">
                            <div
                                className="c-caption-section"
                                style={{ color: "#000000", textAlign: "center" }}
                            >
                                {t("investor-relations")}
                            </div>
                            <h1 className="c-caption-header" style={{ color: "#000000" }}>
                                {t("initial-public-offering")}
                            </h1>
                        </div>
                    </div>
                </div>
                <div
                    className="c-container"
                    style={{
                        paddingTop: "3rem",
                        paddingBottom: "3rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <div
                        className="c-row"
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "1rem",
                            width: "100%",
                            maxWidth: "600px",
                            justifyContent: "space-between",
                        }}
                    >
                        <div
                            className="c-col"
                            style={{
                                flex: "1 1 45%", // Butonların eşit genişlikte olmasını sağlar
                                margin: "0.5rem",
                                minWidth: "150px",
                            }}
                        >
                            <a
                                href="https://www.cevher.com/static/docs/Cevher-Jant-taslak-izahname.pdf"
                                target="_blank"
                                className="c-button"
                                style={{
                                    display: "block",
                                    width: "100%",
                                    padding: "1rem",
                                    textAlign: "center",
                                    marginTop: "1.5rem",
                                }}
                            >
                                {t("draft-prospectus")}
                            </a>
                        </div>
                        <div
                            className="c-col"
                            style={{
                                flex: "1 1 45%",
                                margin: "0.5rem",
                                minWidth: "150px",
                            }}
                        >
                            <a
                                href="https://www.cevher.com/static/docs/fon-kullanim-yeri-raporu.pdf"
                                target="_blank"
                                className="c-button"
                                style={{
                                    display: "block",
                                    width: "100%",
                                    padding: "1rem",
                                    textAlign: "center",
                                    marginTop: "1.5rem",
                                }}
                            >
                                {t("investment-fund-utilization-report")}
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default PublicOfferInfo;