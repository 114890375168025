import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navbar } from "../../Components/Navbar/Navbar";

const Enviromental = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "Sustainability - Cevher";
  }, []);

  return (
    <div>
      <Navbar />
      <div className="c-wrapper">
        <div className="c-hero">
          <div className="c-hero-background">
            <picture>
              <img
                className="c-hero-image"
                alt=""
                src={require("../../assets/hero/environment-policy.deb9f609.jpg")}
              />
            </picture>
          </div>
          <div className="c-hero-body">
            <div className="c-about-caption">
              <div
                className="c-caption-section"
                style={{ color: "#fff", textAlign: "center" }}
              >
                {t("sustainability")}
              </div>
              <h1 className="c-caption-header">
                {t("environmentalpolicyandactivities")}
              </h1>
            </div>
          </div>
        </div>
        <div
          className="c-container"
          style={{ paddingTop: "3rem", paddingBottom: "3rem" }}
        >
          <p>
            <br />
            <strong style={{ textAlign: "start" }}>{t("sustainability-environmental-ouractivities")}</strong>
          </p>
          <p>
            <br />
            {t("sustainability-environmental-ouractivities-1")}
          </p>
          <p>
            <br />
            {t("sustainability-environmental-ouractivities-2")}
          </p>
          <ul style={{ listStyle: "inside" }}>
            <li>{t("sustainability-environmental-ouractivities-3")}</li>
            <li>{t("sustainability-environmental-ouractivities-4")}</li>
            <li>{t("sustainability-environmental-ouractivities-5")}</li>
            <li>{t("sustainability-environmental-ouractivities-6")}</li>
            <li>{t("sustainability-environmental-ouractivities-7")}</li>
            <li>{t("sustainability-environmental-ouractivities-8")}</li>
            <li>{t("sustainability-environmental-ouractivities-9")}</li>
            <li>{t("sustainability-environmental-ouractivities-10")}</li>
            <li>{t("sustainability-environmental-ouractivities-11")}</li>
          </ul>
          <p>
            <br />
            {t("sustainability-environmental-ouractivities-regulations")}
          </p>
          <p>
            <br />
            <strong style={{ textAlign: "start" }}>{t("sustainability-environmental-ouractivities-title-1")}</strong>
          </p>

          <ul style={{ listStyle: "inside" }}>
            <li>{t("sustainability-environmental-ouractivities-12")}</li>
            <li>{t("sustainability-environmental-ouractivities-13")}</li>
          </ul>
          <p>
            <br />
            <strong style={{ textAlign: "start" }}>{t("sustainability-environmental-ouractivities-title-2")}</strong>
          </p>
          <ul style={{ listStyle: "inside" }}>
            <li>{t("sustainability-environmental-ouractivities-14")}</li>
            <li>{t("sustainability-environmental-ouractivities-15")}</li>
          </ul>

          <p>
            <br />
            <strong style={{ textAlign: "start" }}>{t("sustainability-environmental-ouractivities-title-3")}</strong>
          </p>
          <ul style={{ listStyle: "inside" }}>
            <li>{t("sustainability-environmental-ouractivities-16")}</li>
            <li>{t("sustainability-environmental-ouractivities-17")}</li>
            <li>{t("sustainability-environmental-ouractivities-18")}</li>
            <li>{t("sustainability-environmental-ouractivities-19")}</li>
            <li>{t("sustainability-environmental-ouractivities-20")}</li>
            <li>{t("sustainability-environmental-ouractivities-21")}</li>
            <li>{t("sustainability-environmental-ouractivities-22")}</li>
            <li>{t("sustainability-environmental-ouractivities-23")}</li>
          </ul>
          <p>
            <br />
            <strong style={{ textAlign: "start" }}>{t("sustainability-environmental-ouractivities-title-4")}</strong>
          </p>
          <ul style={{ listStyle: "inside" }}>
            <li>{t("sustainability-environmental-ouractivities-24")}</li>
            <li>{t("sustainability-environmental-ouractivities-25")}</li>
            <li>{t("sustainability-environmental-ouractivities-26")}</li>
            <li>{t("sustainability-environmental-ouractivities-27")}</li>
            <li>{t("sustainability-environmental-ouractivities-28")}</li>
            <li>{t("sustainability-environmental-ouractivities-29")}</li>
            <li>{t("sustainability-environmental-ouractivities-30")}</li>
          </ul>
          <p>
            <br />
            <strong style={{ textAlign: "start" }}>{t("sustainability-environmental-ouractivities-title-5")}</strong>
          </p>
          <ul style={{ listStyle: "inside" }}>
            <li>{t("sustainability-environmental-ouractivities-31")}</li>
            <li>{t("sustainability-environmental-ouractivities-32")}</li>
            <li>{t("sustainability-environmental-ouractivities-33")}</li>
            <li>{t("sustainability-environmental-ouractivities-34")}</li>
          </ul>

          <p>
            <br />
            <strong style={{ textAlign: "start" }}>{t("sustainability-environmental-ouractivities-title-6")}</strong>
          </p>
          <ul style={{ listStyle: "inside" }}>
            <li>{t("sustainability-environmental-ouractivities-35")}</li>
            <li>{t("sustainability-environmental-ouractivities-36")}</li>
            <li>{t("sustainability-environmental-ouractivities-37")}</li>
            <li>{t("sustainability-environmental-ouractivities-38")}</li>
            <li>{t("sustainability-environmental-ouractivities-39")}</li>
            <li>{t("sustainability-environmental-ouractivities-40")}</li>
            <li>{t("sustainability-environmental-ouractivities-41")}</li>
          </ul>

          <p>
            <br />
            <strong style={{ textAlign: "start" }}>{t("sustainability-environmental-ouractivities-title-7")}</strong>
          </p>
          <ul style={{ listStyle: "inside" }}>
            <li>{t("sustainability-environmental-ouractivities-42")}</li>
            <li>{t("sustainability-environmental-ouractivities-43")}</li>
            <li>{t("sustainability-environmental-ouractivities-44")}</li>
            <li>{t("sustainability-environmental-ouractivities-45")}</li>
          </ul>
          <p>
            <br />
            <strong style={{ textAlign: "start" }}>{t("sustainability-environmental-ouractivities-title-8")}</strong>
          </p>
          <p>
            <br />
            <strong style={{ textAlign: "start" }}>{t("sustainability-environmental-ouractivities-title-9")}</strong>
          </p>
          <ul style={{ listStyle: "inside" }}>
            <li>{t("sustainability-environmental-ouractivities-46")}</li>
          </ul>
          <p>
            <br />
            <strong style={{ textAlign: "start" }}>{t("sustainability-environmental-ouractivities-title-10")}</strong>
          </p>
          <ul style={{ listStyle: "inside" }}>
            <li>{t("sustainability-environmental-ouractivities-47")}</li>
          </ul>
          <p>
            <br />
            <strong style={{ textAlign: "start" }}>{t("sustainability-environmental-ouractivities-title-11")}</strong>
          </p>
          <ul style={{ listStyle: "inside" }}>
            <li>{t("sustainability-environmental-ouractivities-48")}</li>
            <li>{t("sustainability-environmental-ouractivities-49")}</li>
            <li>{t("sustainability-environmental-ouractivities-50")}</li>
            <li>{t("sustainability-environmental-ouractivities-51")}</li>
          </ul>
          <p>
            <br />
            <strong style={{ textAlign: "start" }}>{t("sustainability-environmental-ouractivities-title-12")}</strong>
          </p>
          <ul style={{ listStyle: "inside" }}>
            <li>{t("sustainability-environmental-ouractivities-52")}</li>
            <li>{t("sustainability-environmental-ouractivities-53")}</li>
            <li>{t("sustainability-environmental-ouractivities-54")}</li>
          </ul>

          <p>
            <br />
            <strong style={{ textAlign: "start" }}>{t("sustainability-environmental-ouractivities-title-13")}</strong>
          </p>
          <ul style={{ listStyle: "inside" }}>
            <li>{t("sustainability-environmental-ouractivities-55")}</li>
            <li>{t("sustainability-environmental-ouractivities-56")}</li>
          </ul>

          <p>
            <br />
            <strong style={{ textAlign: "start" }}>{t("sustainability-environmental-ouractivities-title-14")}</strong>
          </p>
          <ul style={{ listStyle: "inside" }}>
            <li>{t("sustainability-environmental-ouractivities-57")}</li>
            <li>{t("sustainability-environmental-ouractivities-58")}</li>
            <li>{t("sustainability-environmental-ouractivities-59")}</li>
            <li>{t("sustainability-environmental-ouractivities-60")}</li>
            <li>{t("sustainability-environmental-ouractivities-61")}</li>
            <li>{t("sustainability-environmental-ouractivities-62")}</li>
          </ul>




        </div>
      </div>
    </div>
  );
};

export default Enviromental;
