import { Link } from "react-router-dom";
import { BsLinkedin } from "react-icons/bs";
import "./Footer.styles.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export const Footer = () => {
    const { t } = useTranslation();
    const language = i18next.language;

    return (
        <div className="c-footer-wrapper">
            <div className="c-footer">
                <div className="container">
                    <div
                        className="c-caption"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                        }}
                    >
                        <div className="c-caption-title" style={{ color: "#fff" }}>
                            {t("contactus")}
                        </div>
                        <div style={{ marginTop: "1.5rem" }}>
                            <a href="/#/contact" className="c-button">
                                {t("getintouch")}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="c-container" style={{ marginTop: "3rem", marginBottom: "3rem" }}>
                    <div className="c-footer-row">
                        <div className="c-footer-col">
                            <div className="c-footer-primary">{t("corporate")}</div>
                            <ul className="c-footer-list">
                                <li className="c-footer-list-item">
                                    <Link to="/corporate/about" className="c-footer-list-link">
                                        {t("aboutus")}
                                    </Link>
                                </li>
                                <li className="c-footer-list-item">
                                    <a href="/#/corporate/history" className="c-footer-list-link">
                                        {t("ourhistory")}
                                    </a>
                                </li>
                                <li className="c-footer-list-item">
                                    <Link to="/corporate/clients" className="c-footer-list-link">
                                        {t("ourcustomers")}
                                    </Link>
                                </li>
                                <li className="c-footer-list-item">
                                    <Link to="/corporate/values" className="c-footer-list-link">
                                        {t("visionmissionvaluesandethics")}
                                    </Link>
                                </li>
                                <li className="c-footer-list-item">
                                    <Link
                                        to="/products/social-responsibility"
                                        className="c-footer-list-link"
                                    >
                                        {t("socialresponsibility")}
                                    </Link>
                                </li>
                                <li className="c-footer-list-item">
                                    <a
                                        href="https://cevher.com/static/docs/kvkk-request.pdf"
                                        target="_blank"
                                        className="c-footer-list-link"
                                    >
                                        {t("sustainability-protection-kvkk-request")}
                                    </a>
                                </li>
                                <li className="c-footer-list-item">
                                    <a
                                        href="https://cevher.com/static/docs/kvkk-info.pdf"
                                        target="_blank"
                                        className="c-footer-list-link"
                                    >
                                        {t("sustainability-protection-kvkk-info")}
                                    </a>
                                </li>
                                <li className="c-footer-list-item">
                                    <Link
                                        to="/corporate/information-community-system"
                                        className="c-footer-list-link"
                                    >
                                        {t("information-community-system")}
                                    </Link>
                                </li>
                                
                            </ul>
                        </div>
                        <div className="c-footer-col">
                            <div className="c-footer-primary">{t("products")}</div>
                            <ul className="c-footer-list">
                                <li className="c-footer-list-item">
                                    <Link to="/products/oem" className="c-footer-list-link">
                                        {t("alloywheelsforoem")}
                                    </Link>
                                </li>
                                <li className="c-footer-list-item">
                                    <Link
                                        to="/products/after-market"
                                        className="c-footer-list-link"
                                    >
                                        {t("alloywheelsforaftermarket")}
                                    </Link>
                                </li>
                                <li className="c-footer-list-item">
                                    <Link
                                        to="/products/chassis-and-powertrain"
                                        className="c-footer-list-link"
                                    >
                                        {t("chassisandpowertrain")}
                                    </Link>
                                </li>
                            </ul>
                            <div className="c-footer-primary" style={{ marginTop: "1.5rem" }}>
                                {t("developmentandproduction")}
                            </div>
                            <ul className="c-footer-list">
                                <li className="c-footer-list-item">
                                    <Link
                                        to="/development-and-production/rnd-engineering"
                                        className="c-footer-list-link"
                                    >
                                        {t("r&dandengineering")}
                                    </Link>
                                </li>
                                <li className="c-footer-list-item">
                                    <Link
                                        to="/development-and-production/quality"
                                        className="c-footer-list-link"
                                    >
                                        {t("quality")}
                                    </Link>
                                </li>
                                <li className="c-footer-list-item">
                                    <Link
                                        to="/development-and-production/production"
                                        className="c-footer-list-link"
                                    >
                                        {t("production")}
                                    </Link>
                                </li>
                                <li className="c-footer-list-item">
                                    <Link to="/industrial-design" className="c-footer-list-link">
                                        {t("industrialdesign")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="c-footer-col">
                            <div className="c-footer-primary">{t("sustainability")}</div>
                            <ul className="c-footer-list">
                                <li className="c-footer-list-item">
                                    <Link
                                        to="/sustainability/sustainability-report"
                                        className="c-footer-list-link"
                                    >
                                        {t("sustainabilityreport")}
                                    </Link>
                                </li>
                                <li className="c-footer-list-item">
                                    <Link
                                        to="/sustainability/environmental-policy-and-activities"
                                        className="c-footer-list-link"
                                    >
                                        {t("environmentalpolicyandactivities")}
                                    </Link>
                                </li>
                                <li className="c-footer-list-item">
                                    <Link
                                        to="/sustainability/iso-14001-2015-scope"
                                        className="c-footer-list-link"
                                    >
                                        {t("isoscope")}
                                    </Link>
                                </li>
                                <li className="c-footer-list-item">
                                    <Link
                                        to="/sustainability/energy-management-policy"
                                        className="c-footer-list-link"
                                    >
                                        {t("energymanagementpolicy")}
                                    </Link>
                                </li>
                                <li className="c-footer-list-item">
                                    <Link
                                        to="/sustainability/green-purchasing"
                                        className="c-footer-list-link"
                                    >
                                        {t("green-purchasing-policy")}
                                    </Link>
                                </li>
                                <li className="c-footer-list-item">
                                    <Link
                                        to="/development-and-production/quality"
                                        className="c-footer-list-link"
                                    >
                                        {t("quality-documents")}
                                    </Link>
                                </li>
                                <li className="c-footer-list-item">
                                    <Link
                                        to="/sustainability/occupational-health-and-safety-policy-and-activities"
                                        className="c-footer-list-link"
                                    >
                                        {t("occupationalhealthandsafetypolicy")}
                                    </Link>
                                </li>
                                <li className="c-footer-list-item">
                                    <Link
                                        to="/sustainability/social-compliance-policy"
                                        className="c-footer-list-link"
                                    >
                                        {t("socialcompliancepolicy")}
                                    </Link>
                                </li>

                                <li className="c-footer-list-item">
                                    <Link
                                        to="/sustainability/modern-slavery-policy"
                                        className="c-footer-list-link"
                                    >
                                        {t("slavery-policy-title")}
                                    </Link>
                                </li>

                                <li className="c-footer-list-item">
                                    <Link
                                        to="/sustainability/information-security-policy"
                                        className="c-footer-list-link"
                                    >
                                        {t("informationsecuritypolicy")}
                                    </Link>
                                </li>
                                <li className="c-footer-list-item">
                                    <Link
                                        to="/sustainability/supplier-sustainability-policy"
                                        className="c-footer-list-link"
                                    >
                                        {t("supplier-sustainability-policy")}
                                    </Link>
                                </li>
                                {/* <li className="c-footer-list-item">
                                    <Link
                                        to="/sustainability/protection-of-personal-data"
                                        className="c-footer-list-link"
                                    >
                                        {t("protectionofpersonaldata")}
                                    </Link>
                                </li> */}
                            </ul>
                        </div>
                        
                        <div className="c-footer-col">
                        <div className="c-footer-primary" style={{marginBottom:0}}>{t("investor-relations")}</div>
                            <ul className="c-footer-list">
                                <li className="c-footer-list-item" style={{marginBottom:"1rem"}}>
                                    <Link
                                        to="/investor-relations/initial-public-offering"
                                        className="c-footer-list-link"
                                    >
                                        {t("initial-public-offering")}
                                    </Link>
                                </li>
                                </ul>

                            <div className="c-footer-primary">
                                <Link to="/career">{t("career")}</Link>
                            </div>
                            <div className="c-footer-primary">
                                <Link to="/news">{t("news")}</Link>
                            </div>
                            <div className="c-footer-primary">
                                <Link to="/contact">{t("contact")}</Link>
                            </div>

                            <div
                                className="c-footer-primary"
                                style={{ marginBottom: "1rem" }}
                            >
                                {t("contactus")}
                            </div>
                            <div style={{ fontSize: "0.875rem", color: "#fff" }}>
                                <div>
                                    ZAFER SB MAH. KÜRŞAD SK. NO: 10 İÇ KAPI NO: 1 GAZİEMİR/ İZMİR , Turkiye
                                </div>
                                <div style={{ marginTop: "1rem" }}>
                                    <div>
                                        <strong>{t("phone")}: </strong>
                                        <a href="tel:+90 (232) 298 20 00">+90 (232) 298 20 00</a>
                                    </div>
                                    <div>
                                        <strong>{t("fax")}: </strong>+90 (232) 298 20 98
                                    </div>
                                    <div>
                                        <strong>{t("email")}: </strong>{" "}
                                        <a href="mailto:info@cevherwheels.com">
                                            info@cevherwheels.com
                                        </a>
                                    </div>
                                    <a href="https://www.linkedin.com/company/cevher-jant-sanayi-as/">
                                        <BsLinkedin size={24} className="linkedin-btn" />
                                    </a>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img style={{ width: '100%', maxWidth: '600px', height: 'auto' }} src={require("../../assets/img/cevher_kart" + (language == 'en' ? '_en' : '') + ".png")}></img>
                </div>
            </div>
        </div>
    );
};
