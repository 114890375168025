import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import "./App.css";
import { Footer } from "./Components/Footer/Footer";

import About from "./Pages/About";
import Customers from "./Pages/Customers";
import Home from "./Pages/Home";
import OEM from "./Pages/OEM";
import Social from "./Pages/SocialResponsibility";
import Values from "./Pages/Values";
import AfterMarket from "./Pages/AfterMarket";
import CaP from "./Pages/Chassis";
import RND from "./Pages/RND";
import Quality from "./Pages/Quality";
import Production from "./Pages/Production";
import IndustrialDesign from "./Pages/IndustrialDesign";
import Enviromental from "./Pages/Environmental";
import EnvAndEnergy from "./Pages/EnvAndEnergy";
import ISO from "./Pages/ISO";
import Energy from "./Pages/Energy";
import SocialCompliance from "./Pages/SocialComplience";
import Health from "./Pages/Health";
import Security from "./Pages/Security";
import Protection from "./Pages/Protection";
import SustainabilityReport from "./Pages/SustainabilityReport";
import GreenPurchase from "./Pages/GreenPurchase";
import Career from "./Pages/Career";
import News from "./Pages/News";
import New from "./Pages/NewPage";
import { useEffect } from "react";
import Contact from "./Pages/Contact";
import History from "./Pages/History";
import Products from "./Pages/Products";
import { StudentProgram } from "./Pages/StudentPrograms/StudentPrograms";
import ModernSlaveryPolicy from "./Pages/ModernSlavery";
import SupplierSustainability from "./Pages/SupplierSustaninability";
import InformationCommunity from "./Pages/InformationCommunity";
import PublicOfferInfo from "./Pages/PublicOfferInfo";


function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/corporate/about" element={<About />} />
        <Route path="/corporate/history" element={<History />} />
        <Route path="/corporate/clients" element={<Customers />} />
        <Route path="/corporate/values" element={<Values />} />
        <Route path="/corporate/information-community-system" element={<InformationCommunity />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/oem" element={<OEM />} />
        <Route path="/products/social-responsibility" element={<Social />} />
        <Route path="/products/after-market" element={<AfterMarket />} />
        <Route path="/products/chassis-and-powertrain" element={<CaP />} />
        <Route
          path="/development-and-production/rnd-engineering"
          element={<RND />}
        />
        <Route
          path="/development-and-production/quality"
          element={<Quality />}
        />
        <Route
          path="/development-and-production/production"
          element={<Production />}
        />
        <Route path="/industrial-design" element={<IndustrialDesign />} />
        <Route
          path="/sustainability/environment-and-energy-policy"
          element={<EnvAndEnergy/>}
        />
        <Route
          path="/sustainability/environmental-policy-and-activities"
          element={<Enviromental />}
        />
        <Route path="/sustainability/iso-14001-2015-scope" element={<ISO />} />
        <Route
          path="/sustainability/energy-management-policy"
          element={<Energy />}
        />
        <Route
          path="/sustainability/green-purchasing"
          element={<GreenPurchase />}
        />
        <Route
          path="/sustainability/occupational-health-and-safety-policy-and-activities"
          element={<Health />}
        />
        <Route
          path="/sustainability/social-compliance-policy"
          element={<SocialCompliance />}
        />
        <Route
          path="/sustainability/modern-slavery-policy"
          element={<ModernSlaveryPolicy/>}
        />
        <Route
          path="/sustainability/information-security-policy"
          element={<Security />}
        />
        <Route
          path="/sustainability/protection-of-personal-data"
          element={<Protection />}
        />
        <Route
          path="/sustainability/sustainability-report"
          element={<SustainabilityReport />}
        />

        <Route path="/sustainability/supplier-sustainability-policy" element={<SupplierSustainability />} />



        <Route path="/career" element={<Career />} />
        <Route path="/student-programs" element={<StudentProgram />} />
        <Route path="/news" element={<News />} />
        <Route path="investor-relations/initial-public-offering" element={<PublicOfferInfo />} />

        <Route path="/:link" element={<New />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="*"
          element={<Navigate to="/" />}
          component={<div>404</div>}
        />
        
      </Routes>
      <Footer />
    </>
  );
}

export const App_TR = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/corporate/about" element={<About />} />
        <Route path="/corporate/history" element={<History />} />
        <Route path="/corporate/clients" element={<Customers />} />
        <Route path="/corporate/values" element={<Values />} />
        <Route path="/corporate/information-community-system" element={<InformationCommunity />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/oem" element={<OEM />} />
        <Route path="/products/social-responsibility" element={<Social />} />
        <Route path="/products/after-market" element={<AfterMarket />} />
        <Route path="/products/chassis-and-powertrain" element={<CaP />} />
        <Route
          path="/development-and-production/rnd-engineering"
          element={<RND />}
        />
        <Route
          path="/development-and-production/quality"
          element={<Quality />}
        />
        <Route
          path="/development-and-production/production"
          element={<Production />}
        />
        <Route path="/industrial-design" element={<IndustrialDesign />} />
        <Route
          path="/sustainability/environmental-policy-and-activities"
          element={<Enviromental />}
        />
        <Route path="/sustainability/iso-14001-2015-scope" element={<ISO />} />
        <Route
          path="/sustainability/energy-management-policy"
          element={<Energy />}
        />
        <Route
          path="/sustainability/occupational-health-and-safety-policy-and-activities"
          element={<Health />}
        />
        <Route
          path="/sustainability/information-security-policy"
          element={<Security />}
        />
        <Route
          path="/sustainability/protection-of-personal-data"
          element={<Protection />}
        />
        <Route
          path="/sustainability/sustainability-report"
          element={<SustainabilityReport />}
        />
        <Route path="/career" element={<Career />} />
        <Route path="/news" element={<News />} />
        <Route path="investor-relations/initial-public-offering" element={<PublicOfferInfo />} />
        <Route path="/:link" element={<New />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="*"
          element={<Navigate to="/" />}
          component={<div>404</div>}
        />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
